import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { ProgressIndicator } from '../components/ProgressIndicator';

import AppDrawer from '../components/AppDrawer';
import api from '../api';
import { Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { trackEvent } from '../lib/tracking';
import { buildConvPassthruUrl } from '../lib/utilities';
import { Feature, hasFeature } from '../lib/features';
import Cookies from 'js-cookie';

function ConversationEnd() {
  const loggedIn = Cookies.get('isAuthenticated') === 'true';
  const navigate = useNavigate();
  const location = useLocation();
  const features = useSelector((state: RootState) => state.auth.features);
  const { uuid = '', correlationUuid = undefined } = useParams();
  const [loading, setLoading] = useState(false);
  const [allowViewReport] = useState(hasFeature(Feature.VIEW_REPORT_DETAILS, features));

  useEffect(() => {
    if (loggedIn) {
      setLoading(true);
      api.completeConversation(uuid).then(() => {
        setLoading(false);
        if (allowViewReport) {
          navigate(`/report/${uuid}`);
        }
      }).catch(reason => {
        setLoading(false);
        console.error('Error completeConversation:', reason);
        navigate(`/courses`);
      });
    } else if (correlationUuid) {
      setLoading(true);
      api.publicCompleteConversation(uuid, correlationUuid).then(() => {
        setLoading(false);
      }).catch(reason => {
        setLoading(false);
        console.error('Error completeConversation:', reason);
      });
    }
  }, []);

  const handleViewConversationClick = async () => {
    trackEvent('Button', 'Click', 'View Conversation Report');
    if (!loggedIn) {
      navigate(buildConvPassthruUrl('/signup', uuid, correlationUuid), { state: { next: `/report/${uuid}` } });
      }
  };

  const handleReturnToScenarios = async () => {
    navigate('/scenarios');
  };

  const ViewReportBox = () => {
    return (
      <Box
        sx={{
          width: 320,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '16px',
          p: 4,
        }}
      >
        <Typography variant="body1" fontWeight="bold" sx={{ pb: 2 }}>
          You Ended the Conversation
        </Typography>
        <Typography variant="body2" sx={{ pb: 4 }}>
          The conversation report is now available for review.
        </Typography>
        <Button variant="contained" onClick={handleViewConversationClick} startIcon={<ArticleOutlinedIcon />}> View Conversation Report</Button>
      </Box>
    );
  };

  const TrialReportExclusionBox = () => {
    return (
      <Box
        sx={{
          width: 320,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '16px',
          p: 4,
        }}
      >
        <Typography variant="body1" fontWeight="bold" sx={{ pb: 2 }}>
          You Ended the Conversation
        </Typography>
        <Typography variant="body2" sx={{ pb: 4 }}>
          Your user account is part of a trial for your company, which means at this time we cannot show you the analysis of your conversation.
          On completion of the trial we will provide you with access to all of your reports.
        </Typography>
        <Button variant="contained" onClick={handleReturnToScenarios} startIcon={<ArticleOutlinedIcon />}> Want to try another Scenario?</Button>
      </Box>
    );
  };

  console.log('loggedIn:', loggedIn, 'loading:', loading, 'allowViewReport:', allowViewReport);
  const mainContent = (
    <>
      <Box sx={{
        minHeight: 'calc(100vh - 64px)',
        backgroundImage: 'url(/sample_report.jpg)',
        backgroundSize: '100% auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        position: 'relative',
      }}>
        {/* Added overlay with 20% opacity */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          }}
        />

        { loading && (
          <ProgressIndicator projectedTimeMs={8000} intervalMs={500} isComplete={!loading} message=  "Please wait. Generating report..."/>
        )}

        { (!(loggedIn || loading) && allowViewReport) ? <ViewReportBox /> : null }
        { (loggedIn && !loading && !allowViewReport) ? <TrialReportExclusionBox /> : null }
      </Box>
    </>
  );

  return (
    <AppDrawer pageName="Conversation" mainContent={mainContent} loginRedirect={location.pathname} />
  );
}

export default ConversationEnd;
