import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

export const SplashScreen = () => {
  return (
    <>
      <header>
        <Typography variant="h5" fontWeight="normal">Let's Have a Short Conversation</Typography>
        <Typography variant="body1">
          Please let us help you to get familiar with our AI chat system. We are going to ask you
          few question and feel free to answer them by talking through your microphone.
        </Typography>
      </header>
      <Link to="/start/prompt">
        <Button
          variant="contained"
          color="primary"
          type="submit"
        >
            Start the Conversation
        </Button>
      </Link>
    </>
  );
};
