import { Box, Typography } from "@mui/material";
import { ReactNode, useEffect, useRef } from "react";
import styled, { css, keyframes } from "styled-components";

export type TranscriptEntry = {
  content: string;
  role: 'user' | 'assistant';
  isSeed: boolean;
};

type TranscriptProps = {
  transcript: TranscriptEntry[];
  botName: string | undefined;
};

const popAnimation = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  60% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
`;

const popAnimationCss = css`
  animation: ${popAnimation} 0.5s ease-out forwards;
`;

const BaseChatBubble = styled(Box)`
  margin-bottom: 1rem;
  padding: 1rem;
  width: 90%;
  max-width: 90%;
`;

const AssistantChatBubble = styled(BaseChatBubble)`
  color: #000000;
  background-color: #f0f1f3;
  border-radius: 0px 10px 10px 10px;
`;

const UserChatBubble = styled(BaseChatBubble)`
  color: #ffffff;
  background-color: #226edc;
  border-radius: 10px 0px 10px 10px;
`;

type ChatMessageProps = {
  role: "user" | "assistant";
  children: ReactNode;
  animate?: boolean;
};

const ChatMessage = ({ role, children }: ChatMessageProps) => {
  return role === "assistant" ? (
    <AssistantChatBubble >
      {children}
    </AssistantChatBubble>
  ) : (
    <UserChatBubble>
      {children}
    </UserChatBubble>
  );
};

const ChatBubbleList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin-block-start: 0.4rem;
  margin-block-end: 1.4rem;
`

type ChatBubbleListItemProps = {
  role: 'user' | 'assistant';
};

const ChatBubbleListItem = styled.li<ChatBubbleListItemProps>`
  display: flex;
  justify-content: ${({ role }) => (role === 'assistant' ? 'flex-start' : 'flex-end')};
  flex-direction: column;
  align-items: ${({ role }) => (role === 'assistant' ? 'flex-start' : 'flex-end')};
`;

export const Transcript = ({
  transcript,
  botName
}: TranscriptProps) => {
  const scrollRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [JSON.stringify(transcript)]);

  return (
    <ChatBubbleList>
      {transcript.map(({ content, role }, index) => {
        const name = role === 'assistant' ? botName : 'You';

        return (
          <ChatBubbleListItem role={role} key={`transcript-${index}`}>
            {
              name && (
                <Box mb={0.5} textAlign={role === 'assistant' ? 'left' : 'right'}>
                  <Typography variant="body2"> {name} </Typography>
                </Box>
              )
            }
            <ChatMessage role={role}>
              <Typography variant="body2" sx={{ textAlign: role === 'assistant' ? 'left' : 'right' }}>
                {content}
              </Typography>
            </ChatMessage>
          </ChatBubbleListItem>
        );
      })}
      <li ref={scrollRef} />
    </ChatBubbleList>
  )
};
