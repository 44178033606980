import { useLocalStorage } from "@uidotdev/usehooks";
import set from "lodash.set";
import get from "lodash.get";

type UserPreferences = {
  audio?: {
    preferredInputDeviceId: string
  }
};

export const usePreferences = () => {
  const [ preferences, setPreferences ] = useLocalStorage<UserPreferences>('mindbloom', {

  });

  const setPreference = (path: string | string[], value: any) => {
    setPreferences(prev => {
      return set({ ...prev }, path, value);
    });
  }

  const getPreference = (path: string) => {
    return get(preferences, path);
  }

  const getPreferences = () => {
    return preferences;
  }
 
  return {
    setPreference,
    getPreference,
    getPreferences
  };
};
