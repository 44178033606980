import { useCallback, useEffect, useState } from "react";
import { getErrorMessage } from "../../../lib/errors";

type UseMediaStreamState = {
  stream: MediaStream | null;
  error: string | null;
};

export const useMediaStream = (constraints: MediaStreamConstraints) => {
  const [state, setState] = useState<UseMediaStreamState | null>(null);
  
  const getMediaStream = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      setState({ stream, error: null });
    } catch (err) {
      setState({ stream: null, error: getErrorMessage(err) });
    }
  }, [constraints]);

  useEffect(() => {
    getMediaStream();
  }, [getMediaStream]);

  return state;
};
