import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { useMediaStream } from '../../components/conversations/hooks/useMediaStream';
import { useSocket } from '../../components/conversations/hooks/useSocket';
import { usePreferences } from '../../hooks/usePreferences';

// @ts-ignore
import outputWorkletUrl from '../../components/conversations/worklets/output.js?url';

enum SCENARIO_EVENT {
  REQUEST_INITIAL_QUESTION = 'request_initial_question',
  REQUEST_FOLLOW_UP_QUESTION = 'request_follow_up_question',
  AUDIO_CHUNK = 'audio_chunk',
  AUDIO_FINAL = 'audio_final',
};

type UserPromptProps = {
  user: {
    accessToken: string
  }
};

type UserPromptState = {
  micAvailable: boolean;
  turn: 'bot' | 'user';
};

export const UserPrompt = ({
  user
}: UserPromptProps) => {
  const audioContextRef = useRef<AudioContext | null>(null);
  const outputWorkletNodeRef = useRef<AudioWorkletNode | null>(null);
  
  const [state, setState ] = useState<UserPromptState>({
    micAvailable: false,
    turn: 'bot'
  });

  const { getSocket } = useSocket(`${import.meta.env.VITE_SOCKET_URL}/scenarios`, {
    accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJncmFudHMiOlsid3JpdGUiLCJyZWFkIl0sImlhdCI6MTczODk0Nzc3NCwiZXhwIjo0MzMwOTQ3Nzc0LCJhdWQiOiJsb2NhbGhvc3QiLCJpc3MiOiJsb2NhbGhvc3QiLCJzdWIiOiJlZGVhYWE3NC1jMzc0LTRjNTYtYmFjNC01OTk1NjFmYTVlZjQifQ.8o5Tn1QKXW6hwLKb99QLkkUnFKASj9CCdTehNh-GWzo',
  });

  const { getPreference } = usePreferences();

  const audioPreferences = getPreference('audio');

  /**
   * Input stream management.
   */
  const constraints = useMemo<MediaStreamConstraints>(() => {    
    return audioPreferences?.preferredInputDeviceId
      ? { audio: { deviceId: { exact: audioPreferences.preferredInputDeviceId } } }
      : { audio: true };
  }, [audioPreferences?.preferredInputDeviceId]);

  const mediaStream = useMediaStream(constraints);

  useEffect(() => {
    if (mediaStream?.stream) {
      setState((prev) => ({ ...prev, micAvailable: true })); 
      
      if (state.turn === 'bot') {
        mediaStream.stream
          .getAudioTracks()
          .forEach((track) => (track.enabled = false));
      } else {
        mediaStream.stream
          .getAudioTracks()
          .forEach((track) => (track.enabled = true));
      }
    }
  }, [mediaStream, state.turn]);

  /**
   * Socket management.
   */
  useEffect(() => {
    const socket = getSocket();
    if (!socket) return;

    const handleAudioChunk = (chunk: ArrayBuffer) => {
      if (outputWorkletNodeRef.current) {
        outputWorkletNodeRef.current.port.postMessage({ type: 'buffer', buffer: chunk });
      }
    };

    socket.on('audio_chunk', handleAudioChunk);

    return () => {
      socket.off('audio_chunk', handleAudioChunk);
    };
  }, [getSocket]);

  useEffect(() => {
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    }
  }, []);

  const handleStart = async () => {
    const audioContext = audioContextRef.current = new AudioContext({ sampleRate: 44100 });

    if (audioContext) {
      await audioContext.audioWorklet.addModule(outputWorkletUrl)
      
      outputWorkletNodeRef.current = new AudioWorkletNode(audioContext, 'output-processor');
      
      outputWorkletNodeRef.current.connect(audioContext.destination);

      outputWorkletNodeRef.current.port.onmessage = ({ data }) => {
        if (data.type === 'process' && data.finished) {
          console.log('your turn');
          setState((prev) => ({ ...prev, turn: 'user' }));
        }
      };
    }

    const socket = getSocket();
    
    if (socket) {
      socket.emit(SCENARIO_EVENT.REQUEST_INITIAL_QUESTION); 
    }
  };

  return (
    <>
      { mediaStream?.error && (
        <Alert severity="error">Microphone Unavailable</Alert>
      )}
      <Box>
        <Typography variant="h5" fontWeight="normal">
          Question 1
        </Typography>
        <Typography variant="body1">
          Who at work do you find most challenging to interact with, and what is your role in relation to them?
        </Typography>
      </Box>
      <button onClick={handleStart}>Test</button>
    </>
  );
};
