import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserEnabledFeatures } from '../lib/features';

export type UserProfile = {
  uuid: string;
  firstName: string;
  lastName: string;
};

export type AuthState = {
  profile?: UserProfile;
  token?: string;
  permissions: string[];
  features: UserEnabledFeatures;
};

export const initialState: AuthState = {
  profile: undefined,
  token: undefined,
  permissions: [],
  features: { system: {}, groups: {} },
};

export const toDisplayInitials = (userProfile?: UserProfile): string => {
  if (userProfile) {
    const firstInitial = userProfile.firstName.substring(0, 1).toUpperCase();
    const lastInitial = userProfile.lastName.substring(0, 1).toUpperCase();
    return `${firstInitial}${lastInitial}`;
  } else {
    return '?';
  }
};

export const toDisplayName = (userProfile?: UserProfile): string => {
  return (userProfile) ? `${userProfile.firstName} ${userProfile.lastName}` : '?';
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{ token: string, profile?: UserProfile, features: UserEnabledFeatures }>
    ) => {
      const { token, profile, features} = action.payload;
      state.token = token;
      state.profile = profile;
      state.features = features;
    },
    clearUser: (state) => {
      state.token = undefined;
      state.profile = undefined;
      state.permissions = [];
      state.features = { system: {}, groups: {} };
    },
  },
});

export const { setUser, clearUser } = authSlice.actions;
export default authSlice.reducer;
