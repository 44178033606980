import { useEffect, useRef, useState, useMemo } from 'react';

interface AudioSpectrumOptions {
  fftSize?: number;    // e.g., 256 or 2048
  updateFps?: number;  // frames per second for data retrieval
  minBin?: number;     // lower bin index (~300Hz)
  maxBin?: number;     // upper bin index (~3400Hz)
  changeThreshold?: number; // used for "has changed" check (optional)
}

interface AudioSpectrumData {
  volume: number;     // overall average volume
  spectrum: number[]; // selected frequency bins
}

export function useAudioSpectrum(
  audioNode: AudioNode | null,
  {
    fftSize = 256,
    updateFps = 30,
    minBin = 25,
    maxBin = 290,
    changeThreshold = 2,
  }: AudioSpectrumOptions = {}
): AudioSpectrumData {
  const [data, setData] = useState<AudioSpectrumData>({ volume: 0, spectrum: [] });

  const analyserRef = useRef<AnalyserNode | null>(null);
  const animationIdRef = useRef<number>();
  const lastUpdateRef = useRef<number>(0);
  const lastDataRef = useRef<AudioSpectrumData>({ volume: 0, spectrum: [] });

  useEffect(() => {
    if (!audioNode) return;

    const audioContext = audioNode.context as AudioContext;
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = fftSize;

    audioNode.connect(analyser);
    analyserRef.current = analyser;

    const bufferLength = analyser.frequencyBinCount;

    const freqData = new Uint8Array(bufferLength);
    
    const frameInterval = 1000 / updateFps;

    function animate() {
      const now = Date.now();
      if (now - lastUpdateRef.current >= frameInterval) {
        analyser.getByteFrequencyData(freqData);

        const avg = freqData.reduce((acc, val) => acc + val, 0) / freqData.length;

        const freqSlice = freqData.slice(minBin, maxBin);

        const newData: AudioSpectrumData = {
          volume: avg,
          spectrum: Array.from(freqSlice), 
        };

        if (hasSignificantlyChanged(newData, lastDataRef.current, changeThreshold)) {
          setData(newData);
          lastDataRef.current = newData;
        }

        lastUpdateRef.current = now;
      }
      animationIdRef.current = requestAnimationFrame(animate);
    }

    animate();

    return () => {
      if (animationIdRef.current) {
        cancelAnimationFrame(animationIdRef.current);
      }
      // Clean up audio connections
      audioNode.disconnect(analyser);
      analyser.disconnect();
    };
  }, [audioNode, fftSize, updateFps, minBin, maxBin, changeThreshold]);

  return data;
}

/**
 * Determines if the new data differs enough from the old data.
 */
function hasSignificantlyChanged(
  a: AudioSpectrumData,
  b: AudioSpectrumData,
  threshold: number
): boolean {
  // Compare volume
  if (Math.abs(a.volume - b.volume) > threshold) return true;

  // Optionally compare frequency bins
  if (a.spectrum.length !== b.spectrum.length) return true;

  let diffSum = 0;
  for (let i = 0; i < a.spectrum.length; i++) {
    diffSum += Math.abs(a.spectrum[i] - b.spectrum[i]);
    if (diffSum > threshold) return true;
  }

  return false;
}
