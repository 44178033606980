// @ts-nocheck
import React, { useEffect, useRef, useMemo } from 'react';
import { GlobalStyles, Box } from '@mui/material';
import { interpolate } from "flubber"

const shape1 = "M 100 600 q 0 -500, 500 -500 t 500 500 t -500 500 T 100 600 z";
const shape2 = "M 100 600 q -50 -400, 500 -500 t 450 550 t -500 500 T 100 600 z";
const shape3 = "M 100 600 q 0 -400, 500 -500 t 400 500 t -500 500 T 100 600 z";
const shape4 = "M 150 600 q 0 -600, 500 -500 t 500 550 t -500 500 T 150 600 z";
const shape5 = "M 150 600 q 0 -600, 500 -500 t 500 550 t -500 500 T 150 600 z";
const shape6 = "M 100 600 q 100 -600, 500 -500 t 400 500 t -500 500 T 100 600 z";

// Define eight blobs – four primary and four alt.
const blobsData = [
  { key: 'blob1', base: shape1, target: shape2, className: 'blob blob-1', pulseFrequency: 2, phase: 0 },
  { key: 'blob2', base: shape3, target: shape6, className: 'blob blob-2', pulseFrequency: 2.2, phase: Math.PI / 2 },
  { key: 'blob3', base: shape2, target: shape4, className: 'blob blob-3', pulseFrequency: 1.8, phase: Math.PI },
  { key: 'blob4', base: shape4, target: shape5, className: 'blob blob-4', pulseFrequency: 2.5, phase: 3 * Math.PI / 2 },
  { key: 'blob1Alt', base: shape1, target: shape2, className: 'blob blob-1 alt', pulseFrequency: 2, phase: Math.PI },
  { key: 'blob2Alt', base: shape3, target: shape6, className: 'blob blob-2 alt', pulseFrequency: 2.2, phase: Math.PI / 2 + Math.PI },
  { key: 'blob3Alt', base: shape2, target: shape4, className: 'blob blob-3 alt', pulseFrequency: 1.8, phase: Math.PI + Math.PI },
  { key: 'blob4Alt', base: shape4, target: shape5, className: 'blob blob-4 alt', pulseFrequency: 2.5, phase: 3 * Math.PI / 2 + Math.PI },
];

const globalStyles = (
  <GlobalStyles styles={{
    ':root': {
      '--bg-0': '#101030',
      '--bg-1': '#050515',
      '--blob-1': 'rgba(152,77,223,0.7)',
      '--blob-2': 'rgba(67,68,173,0.7)',
      '--blob-3': 'rgba(116,217,225,0.7)',
      '--blob-4': 'rgba(5,5,21,0.7)',
    },

    '.blobs': {
      width: 'min(40vw, 40vh)',
      height: 'min(40vw, 40vh)',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    '.blobs svg': {
      position: 'relative',
      height: '100%',
      zIndex: 2,
    },
    '.blob': {
      position: 'absolute',
      animation: 'rotate 25s infinite alternate ease-in-out',
      transformOrigin: '50% 50%',
      opacity: 0.7,
    },
    '.blob.alt': {
      animationDirection: 'alternate-reverse',
      opacity: 0.3,
    },
    '.blob path': {
      transition: 'fill 800ms ease, transform 200ms ease',
      transformOrigin: '50% 50%',
    },
    '.blob.blob-1 path': {
      fill: 'var(--blob-1)',
      filter: 'blur(1rem)',
      transform: 'scale(0.8)',
    },
    '.blob.blob-2 path': {
      fill: 'var(--blob-2)',
      filter: 'blur(0.75rem)',
      transform: 'scale(0.78)',
    },
    '.blob.blob-3 path': {
      fill: 'var(--blob-3)',
      filter: 'blur(0.5rem)',
      transform: 'scale(0.76)',
    },
    '.blob.blob-4 path': {
      fill: 'var(--blob-4)',
      filter: 'blur(10rem)',
      transform: 'scale(0.5)',
      opacity: 0.9,
    },
    '.blob.blob-1': {},
    '.blob.blob-2': {
      animationDuration: '18s',
      animationDirection: 'alternate-reverse',
    },
    '.blob.blob-3': {
      animationDuration: '23s',
    },
    '.blob.blob-4': {
      animationDuration: '31s',
      animationDirection: 'alternate-reverse',
    },
    '@keyframes rotate': {
      from: { transform: 'rotate(0deg)' },
      to: { transform: 'rotate(360deg)' }
    }
  }} />
);

const VoiceResponsivePulseBlobs = React.memo(({ analyzer }) => {
  const blobRefs = useRef({});
  const startTime = useRef(performance.now());

  // Precompute interpolators once and store in a memoized object.
  const interpolators = useMemo(() => {
    const cache = {};
    blobsData.forEach(blob => {
      cache[blob.key] = interpolate(blob.base, blob.target, { maxSegmentLength: 2 });
    });
    return cache;
  }, []);

  useEffect(() => {
    if (!analyzer) {
      console.warn('No analyzer node provided.');
      return;
    }
    // Optionally, reduce the FFT size to lessen processing load:
    if (analyzer.fftSize > 256) {
      analyzer.fftSize = 256;
    }
    const bufferLength = analyzer.fftSize;
    const dataArray = new Uint8Array(bufferLength);
    const rmsMultiplier = 0.2;
    const pulseThreshold = 0.02;
    let smoothedIntensity = 0;
    const pulseFrequencyDefault = 2; // default pulse frequency

    let animationId;
    const update = (now) => {
      const elapsed = (now - startTime.current) / 1000;
      analyzer.getByteTimeDomainData(dataArray);
      let sum = 0;
      for (let i = 0; i < bufferLength; i++) {
        const value = dataArray[i] - 128;
        sum += value * value;
      }
      const rms = Math.sqrt(sum / bufferLength);
      const rawIntensity = rms * rmsMultiplier;
      smoothedIntensity = smoothedIntensity * 0.8 + rawIntensity * 0.2;

      blobsData.forEach(blob => {
        let factor = 0;
        if (smoothedIntensity >= pulseThreshold) {
          // Each blob pulses using its own frequency and phase.
          const sineValue = 0.5 * (1 + Math.sin(2 * Math.PI * blob.pulseFrequency * elapsed + blob.phase));
          factor = Math.min(smoothedIntensity, 1) * sineValue;
        }
        const newPath = interpolators[blob.key](factor);
        if (blobRefs.current[blob.key]) {
          blobRefs.current[blob.key].setAttribute('d', newPath);
        }
      });

      animationId = requestAnimationFrame(update);
    };

    update(performance.now());
    return () => cancelAnimationFrame(animationId);
  }, [analyzer, interpolators]);

  return (
    <div className="container">
      <div className="blobs">
        <svg viewBox="0 0 1200 1200">
          {blobsData.map(blob => (
            <g
              key={blob.key}
              className={blob.className}
              ref={el => { blobRefs.current[blob.key] = el?.querySelector('path'); }}
            >
              <path d={blob.base} />
            </g>
          ))}
        </svg>
      </div>
    </div>
  );
});

export default function App({ analyzer }) {
  return (
    <>
      {globalStyles}
      <Box>
        <VoiceResponsivePulseBlobs analyzer={analyzer} />
      </Box>
    </>
  );
}
