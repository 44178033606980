import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Grid, IconButton, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { VolumeMute, VolumeOff } from '@mui/icons-material';
import styled from 'styled-components';
import { useSocket } from '../components/conversations/hooks/useSocket';
import { useSpeechRecognition } from '../components/conversations/speechRecognition';
import { useNavigate, useParams } from 'react-router-dom';
import { Transcript, TranscriptEntry } from '../components/conversations/Transcript';
import { useConversation } from '../components/conversations/hooks/useConversation';
import { useAudioSpectrum } from '../components/conversations/hooks/useAudioSpectrum';
import { BotAvatar } from '../components/conversations/BotAvatar';
import SpeechIndicator from '../components/conversations/ThreeBarIndicator';
import AppDrawer from '../components/AppDrawer';

// Deprecate these:
import { useSelector } from 'react-redux';
import { RootState } from '../store';

// @ts-ignore
import outputWorkletUrl from '../components/conversations/worklets/output.js?url';
// @ts-ignore
import inputWorkletUrl from '../components/conversations/worklets/input.js?url';
import VoiceCircle from '../components/conversations/VoiceCircle';

const UserContainer = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const ControlButton = styled(IconButton)`
  border-radius: 80px !important;
  padding: 10px 20px !important;
  height: 40px;
  background-color: #6a3ef7 !important;
  color: white !important;
  margin: 0 5px !important;
  &:hover {
    background-color: #140048 !important;
  }
  &.Mui-disabled {
    background-color: rgb(204, 204, 204) !important;
    color: rgb(127, 127, 127) !important;
  }
`;

const ControlsContainer = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 12px;
`;

const TranscriptContainer = styled(Box)`
  background: #fff;
  border-radius: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ToggleButtonsHeader = styled(Box)`
  background: #fff;
  padding: 0 1rem; 
  display: flex;
  justify-content: center;
`;

const ScrollableContent = styled(Box)`
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
`;

type ControlState = {
  microphone: boolean;
  camera: boolean;
  speaker: boolean;
};

type ConversationState = {
  status: 'started' | 'idle';
};

export const Conversation = () => {
  const [transcript, setTranscript] = useState<TranscriptEntry[]>([]);

  const [activeTab, setActiveTab] = useState<'summary' | 'transcript'>('summary');

  const [conversationState, setConversationState] = useState<ConversationState>({
    status: 'idle',
  });

  // TODO: Deprecate.
  const { token } = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();
  const { uuid } = useParams();

  // @ts-ignore: I'll augment process.env with the VITE_SOCKET_URL.
  const { getSocket } = useSocket(import.meta.env.VITE_SOCKET_URL, {
    accessToken: token,
    query: {
      uuid
    }
  });

  const audioContextRef      = useRef<AudioContext | null>(null);
  const outputWorkletNodeRef = useRef<AudioWorkletNode | null>(null);
  const inputWorkletNodeRef  = useRef<AudioWorkletNode | null>(null);
  const mediaStreamSourceRef = useRef<MediaStream | null>(null);
  const gainNodeRef          = useRef<GainNode | null>(null);
  const outputAnalyserRef    = useRef<AnalyserNode | null>(null);

  useEffect(() => {
    const initAudioContext = async () => {
      if (!audioContextRef.current) {
        const context = new (window.AudioContext || (window as any).webkitAudioContext)({ sampleRate: 44100 });
        audioContextRef.current = context;

        gainNodeRef.current = context.createGain();
        gainNodeRef.current.gain.setValueAtTime(1, context.currentTime); 
      }
    };

    initAudioContext();
  }, []);

  const { status, conversation } = useConversation(uuid);

  useEffect(() => {
    if (status === 'success') {
      if (conversation && conversation.responses) {
        const transcript = conversation.responses
          .filter(response => response.isSeed)
          .map(({ statement, isBot, isSeed }) => ({
            content: statement,
            role: isBot ? 'assistant' : 'user',
            isSeed
          })) as TranscriptEntry[];

        setTranscript(transcript);
      }
    }
  }, [status]);

  useEffect(() => {
    const socket = getSocket();
    if (!socket) return;

    const handleAudioChunk = (chunk: ArrayBuffer) => {
      if (outputWorkletNodeRef.current) {
        outputWorkletNodeRef.current.port.postMessage({ type: 'buffer', buffer: chunk });
      }
    };

    const handleTranscript = (reply: { transcript: string, role: 'user' | 'assistant', partial: boolean }) => {
      console.log('setting transcript')
      setTranscript((prev) => [
        ...prev,
        {
          content: reply.transcript,
          role: reply.role,
          isSeed: false
        },
      ]);
    };

    const handleBotSpeakingStart = () => {
      if (inputWorkletNodeRef.current) {
        setTurn('bot');
      }
    };
  
    socket.on('bot_speaking_start', handleBotSpeakingStart);
    socket.on('audio_chunk', handleAudioChunk);
    socket.on('transcript', handleTranscript);

    return () => {
      socket.off('audio_chunk', handleAudioChunk);
      socket.off('transcript', handleTranscript);
      socket.off('bot_speaking_start', handleBotSpeakingStart);
    };
  }, [getSocket]);

  const [controls, setControls] = useState<ControlState>({
    microphone: true,
    camera: false,
    speaker: true,
  });

  const [turn, setTurn] = useState<'bot' | 'user' | 'idle'>('idle');

  useEffect(() => {
    console.log('turn:', turn);
    if (turn === 'bot') {
      handleStopRecording();
    } else if (turn === 'user') {
      if (controls.microphone) {
        handleStartRecording();
      }
    }
  }, [turn, controls.microphone]);

//  const spectrum = [];
  const { spectrum } = useAudioSpectrum(outputWorkletNodeRef.current, {
    fftSize: 2048,
    updateFps: 30,
    minBin: 25,
    maxBin: 128,
  });

  const handleStopRecording = () => {
    if (mediaStreamSourceRef.current) {
      mediaStreamSourceRef.current
        .getAudioTracks()
        .forEach((track) => (track.enabled = false));
    }
  };

  const handleStartRecording = () => {
    if (mediaStreamSourceRef.current) {
      mediaStreamSourceRef.current
        .getAudioTracks()
        .forEach((track) => (track.enabled = true));
    }
  };

  const handleSpeakerOn = async () => {
    if (gainNodeRef.current && audioContextRef.current) {
      gainNodeRef.current.gain.setValueAtTime(1, audioContextRef.current.currentTime);
    }
  };

  const handleSpeakerOff = async () => {
    if (gainNodeRef.current && audioContextRef.current) {
      gainNodeRef.current.gain.setValueAtTime(0, audioContextRef.current.currentTime);
    }
  };

  const handleControlClick = (type: keyof ControlState) => () => {
    if (type === 'microphone') {
      if (controls['microphone'] === false) {
        handleStartRecording();
      } else {
        handleStopRecording();
      }
    } else if (type === 'speaker') {
      if (controls['speaker'] === false) {
        handleSpeakerOn();
      } else {
        handleSpeakerOff();
      }
    }

    setControls({
      ...controls,
      [type]: !controls[type],
    });
  };

  const handleStartConversation = async () => {
    if (!audioContextRef.current) {
      console.error('AudioContext is not initialized');
      return;
    }

    const audioContext = audioContextRef.current;

    if (audioContext.state === 'suspended') {
      await audioContextRef.current.resume();
    }

    if (!outputWorkletNodeRef.current) {
      try {
        await audioContext.audioWorklet.addModule(outputWorkletUrl); 
        outputWorkletNodeRef.current = new AudioWorkletNode(audioContext, 'output-processor');

        outputAnalyserRef.current = audioContext.createAnalyser();

        outputWorkletNodeRef.current
          .connect(gainNodeRef.current!)
          .connect(outputAnalyserRef.current)
          .connect(audioContext.destination)
        ;

        outputWorkletNodeRef.current.port.onmessage = ({ data }) => {
          if (data.type === 'process' && data.finished) {
            setTurn('user');
          }
        };
      } catch (error) {
        console.error('Error loading Output Worklet:', error);
      }
    }

    if (!inputWorkletNodeRef.current) {
      try {
        await audioContext.audioWorklet.addModule(inputWorkletUrl); 

        inputWorkletNodeRef.current = new AudioWorkletNode(audioContext, 'audio-input-processor');

        inputWorkletNodeRef.current.port.onmessage = (event) => {
          const socket = getSocket();
          if (socket) {
            socket.emit('user_audio_chunk', event.data);
          }
        };

        //outputAnalyserRef.current = audioContext.createAnalyser();

        mediaStreamSourceRef.current = await navigator.mediaDevices.getUserMedia({ 
          audio: true 
        });

        const mediaStreamSource = audioContext.createMediaStreamSource(mediaStreamSourceRef.current);

        //mediaStreamSource.connect(outputAnalyserRef.current);
        mediaStreamSource.connect(inputWorkletNodeRef.current);
      } catch (error) {
        console.error('Error loading Input Worklet:', error);
      }
    }

    setConversationState((prev) => ({ ...prev, status: 'started' }));
    setActiveTab('transcript');

    const socket = getSocket();
    if (socket) {
      socket.emit('conversation_start', { uuid });
    }
  };

  const handleEndConversation = async () => {
    if (mediaStreamSourceRef.current) {
      mediaStreamSourceRef.current
        .getAudioTracks()
        .forEach((track) => track.stop());
    }
    
    await audioContextRef.current?.close();

    navigate(`/conversation-end/${uuid}`);
  };

  const seedTranscript = useMemo(
    () => transcript.filter(entry => entry.isSeed),
    [transcript]
  );
  
  const nonSeedTranscript = useMemo(
    () => transcript.filter(entry => !entry.isSeed),
    [transcript]
  );

  return (
    <Grid container height={`calc(100vh - 64px)`} p={4} pt={8} overflow={'hidden'}>
      <Grid item xs={12} md={9} pr={2} sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minHeight: '300px',
      }}>
        <UserContainer>
          
          <VoiceCircle analyzer={outputAnalyserRef.current} />
          <BotAvatar name={conversation?.botName} image={conversation?.botAvatar}>
            <Box position="absolute" bottom={8} right={8}>
              {/* <SpeechIndicator spectrum={spectrum} /> */}
            </Box>
          </BotAvatar>
        </UserContainer>
        <ControlsContainer>
          <ControlButton onClick={handleControlClick('microphone')} disabled={turn !== 'user'}>
            {controls.microphone ? <MicNoneOutlinedIcon /> : <MicOffOutlinedIcon />}
          </ControlButton>
          <ControlButton onClick={handleControlClick('speaker')}>
            {controls.speaker ? <VolumeMute /> : <VolumeOff />}
          </ControlButton>
          <ControlButton disabled>
            <VideocamOffOutlinedIcon />
          </ControlButton>

          {conversationState.status === 'idle' && (
            <ControlButton
              sx={{ fontSize: '0.875rem' }}
              onClick={handleStartConversation}
            >
              <PhoneOutlinedIcon sx={{ height: '20px', marginRight: '5px' }} /> Continue
            </ControlButton>
          )}

          {conversationState.status === 'started' && (
            <ControlButton
              sx={{ fontSize: '0.875rem' }}
              onClick={handleEndConversation}
            >
              <PhoneOutlinedIcon sx={{ height: '20px', marginRight: '5px' }} /> End Call and Get Analysis
            </ControlButton>
          )}
        </ControlsContainer>
      </Grid>

      <Grid item xs={12} md={3} px={2} paddingRight={0} height="100%">
        <TranscriptContainer>
          <ToggleButtonsHeader sx={{
            borderRadius: '16px',
            paddingTop: '1rem'
          }}>
            <ToggleButtonGroup
              exclusive
              value={activeTab}
              onChange={(e, value) => value && setActiveTab(value)}
              sx={{
                py: 1,
                '& .MuiToggleButton-root': {
                  textTransform: 'capitalize',
                  fontSize: '0.75rem',
                  padding: '4px 8px',
                },
              }}
            >
              <ToggleButton value="summary">Summary</ToggleButton>
              <ToggleButton value="transcript">Live Transcription</ToggleButton>
            </ToggleButtonGroup>
          </ToggleButtonsHeader>

          <ScrollableContent>
            {activeTab === 'summary' && (
              <>
                {conversation?.brief && (
                  <Box height="100%" overflow="auto">
                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                      Backstory
                    </Typography>
                    <Box>
                      <Typography variant="caption">
                        {conversation.brief}
                      </Typography>
                    </Box>
                    <Box pt={2}>
                      <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                        Conversation History
                      </Typography>
                    </Box>

                    <Transcript transcript={seedTranscript} botName={conversation?.botName} />
                  </Box>
                )}
              </>
            )}
            {activeTab === 'transcript' && (
              <Transcript transcript={nonSeedTranscript} botName={conversation?.botName} />
            )}
          </ScrollableContent>
        </TranscriptContainer>
      </Grid>
    </Grid>
  );
};

export default () => (
  <AppDrawer
    pageName="Conversation"
    mainContent={<Conversation />}
  />
);
