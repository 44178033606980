import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../slices/authSlice';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import AppDrawer from '../components/AppDrawer';
import { SignUpForm } from '../components/user/SignUpForm';
import { ProfileForm } from '../components/user/ProfileForm';
import { Ethnicity, Gender } from '../api/types/common';
import api from '../api';
import { isError } from 'lodash';
import TermsAndConditionsModal from '../components/TermsAndConditionsModal';
import { ProgressIndicator } from '../components/ProgressIndicator';
import { Typography, Stepper, Step, StepButton } from '@mui/material';

enum FormSteps {
  ACCOUNT_SETUP,
  PROFILE_SETUP
};

type SignupFormData = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  licenseAcceptedAt: string;
};

type ProfileFormData = {
  gender: Gender;
  birthYear: number;
  ethnicity: Ethnicity;
  company: string;
  personality: string;
  highestEducation: string;
  jobTitle: string;
  yearsAtCompany: string;
};

export type MultiFormData = SignupFormData & ProfileFormData;

type SignUpProps = {
  onSuccess?: (form: Record<string, string | number>) => void;
}

const UNKNOWN_ERROR = 'An unknown error occurred. Please contact support@mindbloom.ai';

export function isComplete(form: Partial<MultiFormData>): form is MultiFormData {
  return (
    form.username !== undefined &&
    form.firstName !== undefined &&
    form.lastName !== undefined &&
    form.email !== undefined &&
    form.password !== undefined &&
    form.gender !== undefined &&
    form.birthYear !== undefined &&
    form.ethnicity !== undefined &&
    form.company !== undefined &&
    form.personality !== undefined &&
    form.highestEducation !== undefined &&
    form.jobTitle !== undefined &&
    form.yearsAtCompany !== undefined
  );
}

export function SignUp({ onSuccess }: SignUpProps) {
  const [stage, setStep] = useState<FormSteps>(FormSteps.ACCOUNT_SETUP);
  const [form, setForm] = useState<Partial<MultiFormData>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const [termsOpen, setTermsOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>();


  const onSubmit = (data: any) => {
    setForm({
      ...form,
      ...data
    });

    if (stage === FormSteps.ACCOUNT_SETUP) {
      setStep(FormSteps.PROFILE_SETUP);
    }
  }

  const dispatch = useDispatch();

  useEffect(() => {
    const doRegistration = async (form: MultiFormData) => {
      try {
        setLoading(true);

        const res = await api.register(form);
        if (res.status === 'REJECTED') {
          throw new Error(
            res.reason ?? UNKNOWN_ERROR
          );
        }

        if (res.status === 'SUCCESS') {
          if (res.authentication) {
            const { profile, features, token } = res.authentication;

            setTimeout(() => {
              dispatch(
                setUser({
                  profile,
                  features,
                  token
                }),
              );

              setLoading(false);

              if (onSuccess) {
                onSuccess(form);
              }
            }, 5000)
          }
        }
      } catch (err) {
        setLoading(false);

        if (isError(err)) {
          setError(err.message);
        } else {
          setError(UNKNOWN_ERROR);
        }
      }
    };

    if (form && stage === FormSteps.PROFILE_SETUP && isComplete(form)) {
      // if (!termsAgreed) {
      //   setTermsOpen(true);
      // } else {
        doRegistration({
          ...form,
          licenseAcceptedAt: new Date().toISOString()
        });
      // }
    }
  }, [form, stage, termsAgreed])

  return (
    <>
      {loading && (
        <ProgressIndicator projectedTimeMs={2000} intervalMs={100} isComplete={!loading} message="Creating your account" />
      )}

      <Box>
        {stage === FormSteps.ACCOUNT_SETUP && (
          <>
            <Typography variant="h6" sx={{
              pb: 1
            }}>
              Sign up
            </Typography>
            <Typography variant="body2" sx={{
              mb: '1.5rem',
            }}>
              Sign up to join the conversation.
            </Typography>
            <SignUpForm onSubmit={onSubmit} submitLabel="Setup Profile" />
          </>
        )}

        {stage === FormSteps.PROFILE_SETUP && (
          <>
            <Typography variant="h6" sx={{
              pb: 1
            }}>
              Setup My Profile
            </Typography>

            <Typography variant="body2" sx={{
              mb: '1.5rem',
            }}>
              Please fill the form below so that we can build a better conversation experience.
            </Typography>
            <ProfileForm onSubmit={onSubmit} />
          </>
        )}
      </Box>

      <TermsAndConditionsModal
        open={termsOpen}
        handleClose={() => setTermsOpen(false)}
        handleAgreeTerms={() => {
          setTermsAgreed(true);
          setTermsOpen(false);
        }}
      />
    </>
  );

}

const SignUpPage = () => (
  <AppDrawer pageName="" mainContent={
    <Box sx={{
      position: 'absolute',
      top: '10%',
      left: '50%',
      transform: 'translate(-50%, 0%)',
      width: '443px',
      height: 'auto',
    }}
    >
      <Paper sx={{ padding: '2.5rem', borderRadius: 'px' }}>
        <SignUp />
      </Paper>
    </Box>
  } />
);

export default SignUpPage;
