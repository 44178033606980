import React from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Ethnicity, Gender, toEthnicity, toEthnicityCode, toEthnicityText, toGender, toGenderCode, toGenderText } from '../api/types/common';
import api from '../api';
import { GeneratedScenarioDTO, ScenarioSummaryDTO } from '../api/types/scenario';
import { ProgressIndicator } from './ProgressIndicator';
import { getEmotion, getEmotions, getEthnicities, getEthnicity, getGender, getGenders, getPersonalities, getPersonality, getSkills, getSkillSet } from '../lib/masterdata';
import { g } from 'vitest/dist/suite-BWgaIsVn';

const DEFAULT_BOTNAME = 'Wendy the Bot';
const DEFAULT_AGE = '31';
const DEFAULT_GOAL = '';
const DEFAULT_POSITION = 'Senior manager';
const DEFAULT_TENURE = '5';
const DEFAULT_EDUCATION = 'Bachelor degree';

export type ScenarioGeneratedEvent = (scenario: GeneratedScenarioDTO) => void;
export type ScenarioGenerateErrorEvent = (context: string, errMessage: string) => void;

const getFirstName = (fullName: string) => {
  const nameParts = fullName.split(' ');
  return nameParts[0];
};

function yearsElapsed(givenYear: number | undefined): number {
  if (!givenYear) {
    return 0;
  }

  const currentYear = new Date().getFullYear();
  const elapsed = currentYear - givenYear;
  return elapsed >= 0 ? elapsed : 0; 
}


// If the name is changed, then we need substitute any reference to the original first name in the objective.
const updateGoalWithBotName = (goal: string, origName: string, newName: string): string => {
    const origFirstName = getFirstName(origName);
    const newFirstName = getFirstName(newName);
    return (origName !== newName) ? goal.replace(origFirstName, newFirstName) : goal;
};

export const GenerateCustomScenario = ({ item, onGenerated, onError }: {
  item: ScenarioSummaryDTO,
  onGenerated?: ScenarioGeneratedEvent,
  onError?: ScenarioGenerateErrorEvent
}) => {
  const [name, setName] = React.useState<string>((item) ? item.name : '');
  const [botName, setBotName] = React.useState<string>((item) ? item.botName : DEFAULT_BOTNAME);
  const [gender, setGender] = React.useState<Gender>(getGender(item.botGender));
  const [ethnicity, setEthnicity] = React.useState<Ethnicity>(getEthnicity(item.botEthnicity));
  const [emotion, setEmotion] = React.useState(getEmotion(item.botEmotion));
  const [personality, setPersonality] = React.useState(getPersonality(item.botPersonality));
  const [selectedSkills, setSelectedSkills] = React.useState<string[]>(getSkillSet(item.skills));
  const [age, setAge] = React.useState<string>((item?.botAge) ? item.botAge.toString() : DEFAULT_AGE);
  const [conversationGoal, setConversationGoal] = React.useState((item) ? item.objective : DEFAULT_GOAL);
  const [position, setPosition] = React.useState((item?.botJobTitle) ? item.botJobTitle : DEFAULT_POSITION);
  const [tenure, setTenure] = React.useState((item?.botTenureYears) ? item.botTenureYears.toString() : DEFAULT_TENURE);
  const [education, setEducation] = React.useState((item?.botEducation) ? item.botEducation : DEFAULT_EDUCATION);
  const [error, setError] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);

  const handleTenureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Check if the input is a valid integer
    if (/^\d*$/.test(value)) {
      setTenure(value);
    }
  };

  const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Check if the input is a valid integer
    if (/^\d*$/.test(value)) {
      setAge(value);
    }
  };

  const handleSave = async () => {
    if (!conversationGoal.trim()) {
      setError('This is a required field');
      return;
    }
    setLoading(true);
    setError('');
    const user = await api.getMyDetails();
    api.getMyDetails().then(async (details) => {
      const character = await api.inferCharacter({
        gender: gender,
        ethnicity: ethnicity,
        age: parseInt(age)
      });

      // candidateGender and candidateEthnicity is currently fixed.
      // in the future users should be able to specify this in their profile
      const objective = updateGoalWithBotName(conversationGoal, item.botName, botName);
      const scenarioParams = {
        name: name,
        objective: objective,
        scoringMetrics: selectedSkills,
        botName: botName,
        botEmotion: emotion,
        botPersonality: personality,
        botGender: gender,
        botEthnicity: ethnicity,
        botEducation: education,
        botTenureYears: parseInt(tenure),
        botJobTitle: position,
        botAge: parseInt(age),
        botAvatar: character.thumbnail,
        botVoice: character.voice,
        candidateAge: yearsElapsed(user.birthYear),
        candidateGender: Gender.MALE,
        candidateEthnicity: Ethnicity.OTHER_WHITE,
        candidateName: user.firstName + ' ' + user.lastName,
        candidateJobTitle: user.jobTitle,
        candidateEducation: user.highestEducation,
        candidateTenureYears: user.yearsAtCompany,
        personality: user.personality,
      };

      api.generateScenario(scenarioParams).then(response => {
        setLoading(false);
        onGenerated && onGenerated(response);

      }).catch((error) => {
        setLoading(false);
        onError && onError('scenario-generation', error);
      });

    }).catch((error) => {
      setLoading(false);
      onError && onError('user-details', error);
    });
  };

  // Make sure only 2 are selected at any one time
  const handleSelectedSkills = (
    event: any,
    newFormats: string[],
  ) => {
    if (newFormats.length < 2) {
      setSelectedSkills(selectedSkills); // NOP

    } else if (newFormats.length > 2) {
      const remainder = newFormats.filter(value => value !== event.target.value);
      const adjusted = [event.target.value, remainder[0]];
      setSelectedSkills(adjusted);

    } else {
      setSelectedSkills(newFormats);
    }
  };

  return (
    <>

      { loading && (
        <ProgressIndicator projectedTimeMs={28000} intervalMs={500} isComplete={!loading} message=  "Please wait. Generating scenario..."/>
      )}

      <Typography variant="h5" component="h2">
        Describe the conversation
      </Typography>
      <TextField
        fullWidth
        required
        margin="normal"
        label={'Conversation title'}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        fullWidth
        margin="normal"
        multiline
        rows={4}
        label={'Conversation goal'}
        value={updateGoalWithBotName(conversationGoal, item.botName, botName)}
        onChange={(e) => setConversationGoal(e.target.value)}
        required
        error={!!error}
        helperText={error}
      />

      <Typography variant="h5" component="h2">
        What skills do you want to improve?
      </Typography>

      <FormControl fullWidth margin="normal">
        <ToggleButtonGroup
          value={selectedSkills}
          onChange={handleSelectedSkills}
          color={'primary'}
          aria-label="Skills"
        >
          { getSkills().map((skill) => (
            <ToggleButton value={skill} fullWidth>
              {skill}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </FormControl>

      <Typography variant="h5" component="h2">
        About your conversation partner?
      </Typography>

      <TextField
        fullWidth
        margin="normal"
        label="Name"
        value={botName}
        onChange={(e) => setBotName(e.target.value)}
        error={!!error}
        helperText={error}
        required
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="gender-label">Gender</InputLabel>
            <Select
              labelId="gender-label"
              value={gender}
              label="Gender"
              onChange={(e) => setGender(e.target.value as Gender)}
            >
              {getGenders().map((g: Gender) => (
                <MenuItem key={toGenderCode(g)} value={g}>{toGenderText(g)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="ethnicity-label">Ethnicity</InputLabel>
            <Select
              labelId="ethnicity-label"
              value={ethnicity}
              label="Ethnicity"
              onChange={(e) => setEthnicity(e.target.value as Ethnicity)}
            >
              {getEthnicities().map((e: Ethnicity) => (
                <MenuItem key={toEthnicityCode(e)} value={e}>{toEthnicityText(e)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="personality-label">Personality</InputLabel>
            <Select
              labelId="personality-label"
              value={getPersonality(personality)}
              label="Personality"
              onChange={(e) => setPersonality(e.target.value)}
            >
              {getPersonalities().map((p) => (
                <MenuItem key={p} value={p}>{p}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="emotion-label">Emotion</InputLabel>
            <Select
              labelId="emotion-label"
              value={emotion}
              label="Emotion"
              onChange={(e) => setEmotion(getEmotion(e.target.value))}
            >
              {getEmotions().map((e) => (
                <MenuItem key={e} value={e}>{e}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            margin="normal"
            label="Age"
            value={age}
            onChange={handleAgeChange}
            helperText={!/^\d*$/.test(age) ? 'Please enter a valid number' : ''}
            error={!/^\d*$/.test(age)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            margin="normal"
            label="Job title"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            margin="normal"
            label="Highest degree"
            value={education}
            onChange={(e) => setEducation(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            margin="normal"
            label="Years you worked in this company"
            value={tenure}
            onChange={handleTenureChange}
            helperText={!/^\d*$/.test(tenure) ? 'Please enter a valid number' : ''}
            error={!/^\d*$/.test(tenure)}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" sx={{mt: 5}}>
        <Button onClick={handleSave} disabled={loading} variant="contained" color="primary">
          Generate Scenario
        </Button>
      </Grid>
    </>
  );
};

export default Navigator;
