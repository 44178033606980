import React, { useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { ScenarioDTO } from '../api/types/scenario';
import { ScenarioCard, ScenarioCardEditFields } from './ScenarioCard';
import { ScenarioDetails, ScenarioDetailsEditFields } from './ScenarioDetails';
import api from '../api';
import { RootState } from '../store';
import { useSelector } from 'react-redux';

export const EditCustomScenario = ({ scenarioUuid, onSave, onCancel }: {
  scenarioUuid: string,
  onSave: (s: ScenarioDTO) => void,
  onCancel: () => void
}) => {
  const [editScenario, setEditScenario] = useState<ScenarioDTO>();
  const auth = useSelector((state: RootState) => state.auth);

  const onEditCardMerge = (data: ScenarioCardEditFields) => {
    if (editScenario) {
      setEditScenario({
        ...editScenario,
        botAvatar: data.botAvatar,
      });
    }
  };

  const onEditDetailsMerge = (data: ScenarioDetailsEditFields) => {
    if (editScenario) {
      setEditScenario({
        ...editScenario,
        name: data.title,
        objective: data.objective,
        brief: data.brief,
      });
    }
  };

  React.useEffect(() => {
    api.getScenario(scenarioUuid).then((scenario) => {
      console.log('got scenario', scenario);
      setEditScenario(scenario);
    });
  }, []);

  return (
    <>
      <Typography id="modal-title" variant="h6" component="h2">
        Your Custom Generated Scenario
      </Typography>

      {editScenario && (
        <>
          <ScenarioCard scenario={editScenario} onEdit={onEditCardMerge} />
          <Paper style={{ height: 'auto', maxHeight: 500, overflow: 'auto' }} variant="outlined">
            <ScenarioDetails scenario={editScenario} userName={auth.profile?.firstName + ' ' + auth.profile?.lastName} onEdit={onEditDetailsMerge} showBackstory={true} showSeedTranscript={true} />
          </Paper>
        </>
      )}

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="flex-start">
            <Button
              onClick={() => onCancel()}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={() => {
                (editScenario) ? onSave(editScenario) : onCancel();
              }}
              variant="contained"
              color="primary"
              sx={{ mr: 2 }}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Navigator;
