import React, { useState, forwardRef } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputLabel, OutlinedInput, InputAdornment, IconButton, FormControl, FormHelperText, styled } from '@mui/material';

type PasswordInputProps = {
  error?: boolean;
  name?: string;
  label?: string;
};

const OutlinedPasswordInput = styled(OutlinedInput)(
  () => ({
    '.MuiInputBase-input ': {
      padding: '10px 14px',
    }
  })
);

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ error, name = 'password', label = 'Password', ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => setShowPassword((prev) => !prev);

    return (
      <FormControl variant="outlined" fullWidth error={!!error}>
        <OutlinedPasswordInput
          {...props}
          ref={ref}
          id={name}
          name={name}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label={showPassword ? 'Hide password' : 'Show password'}
                onClick={handleTogglePassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
