import * as React from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from './icons/DashboardIcon';
import CoursesIcon from './icons/CoursesIcon';
import ReportsIcon from './icons/ReportsIcon';
import ResourcesIcon from './icons/ResourcesIcon';
import SettingsIcon from './icons/SettingsIcon';
import Box from '@mui/material/Box';
import LogoutIcon from './icons/LogoutIcon';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { useCallback } from 'react';
import { clearUser } from '../slices/authSlice';
import api from '../api';
import { Sidebar } from './navigation/Sidebar';

const iconNames = ['Home', 'Reports', 'Scenarios', 'Settings'] //, 'Resources'];
const iconNamesLoggedInOnly = ['Settings'];
const selectedWhen = [['Home'], ['Report', 'Reports'], ['Scenarios', 'Conversation'], ['Settings'], ['Resources']];
const routes = ['/landing', '/reports', '/courses', '/settings', '/resources'];
const disabledList = ['Resources'];
const iconComponents = [<HomeIcon />, <DashboardIcon />, <ReportsIcon />, <CoursesIcon />, <SettingsIcon />, <ResourcesIcon />, ];

interface DrawerListProps {
  pageName: string;
  open: boolean;
  checkIsLoggedIn: boolean;
  isSmallScreen: boolean;
  mobileOpen: boolean;
};

const findRoute = (text: string): string => {
  const index = iconNames.findIndex((icon, index) => (text === icon));
  return (index >= 0) ? routes[index] : '/scenarios';
};

const DrawerList: React.FC<DrawerListProps> = ({ pageName, open, checkIsLoggedIn, isSmallScreen, mobileOpen }) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const handleItemClick = (text: string) => {
    navigate(findRoute(text));
  };

  const checkIfSelectedByPageName = (pageName: string, index: number): boolean => {
    if (selectedWhen[index].includes(pageName)) {
      return true;
    }
    return false;
  };

  const getValidIconNames = (checkIsLoggedIn: boolean): string[] => {
    return iconNames.filter((iconName) => {
      if (!checkIsLoggedIn) {
        return !iconNamesLoggedInOnly.includes(iconName);
      }
      return true;
    });
  }

  const handleLogout = useCallback(async () => {
    dispatch(clearUser());
    await api.logout();
    navigate('/login');
  }, [dispatch, navigate]);

  return (
    <>
      {/* <Sidebar /> */}

      <List
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: 0,
        }}
      >
        {getValidIconNames(checkIsLoggedIn).map((text: string, index: number) => {
          const isSelected = checkIfSelectedByPageName(pageName, index);
          return (
            <ListItem
              key={text}
              disablePadding
              sx={{
                display: 'block',
                backgroundColor: (isSelected) ? theme.palette.primary.light : 'inherit',
                borderRadius: '8px',
                marginBottom: '12px'
              }}
            >
              <ListItemButton
                disabled={disabledList.includes(text)}
                sx={{
                  minHeight: 40,
                  justifyContent: open || mobileOpen ? 'initial' : 'center',
                  padding: '4px 12px'
                }}
                onClick={() => handleItemClick(text)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open || mobileOpen ? 1 : 'auto',
                    justifyContent: 'center',
                    color: (isSelected) ? '#FFFFFF' : '#989FAD',
                  }}
                >
                  {iconComponents[index]}
                </ListItemIcon>
                <ListItemText primary={text} sx={{
                  opacity: open || mobileOpen ? 1 : 0,
                  color: (isSelected) ? '#FFFFFF' : '#989FAD',
                }} />
              </ListItemButton>
            </ListItem>
            );
        })}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ p: 1, backgroundColor: theme.palette.primary.dark }}>
        {checkIsLoggedIn && (
          <ListItem key="Logout" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 40,
                justifyContent: open || mobileOpen ? 'initial' : 'center',
                p: 0
              }}
              onClick={handleLogout}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open || mobileOpen ? 3 : 'auto',
                  justifyContent: 'center',
                  color: '#989FAD',
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ opacity: open || mobileOpen ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>)}
      </Box>
    </>
  );
};

export default DrawerList;
