import React, { useState } from 'react';
import { Box, Button, Collapse, TextField, Typography } from '@mui/material';
import { GeneratedScenarioDTO, ScenarioDTO } from '../api/types/scenario';
import { Transcript, TranscriptEntry } from './conversations/Transcript';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const getFirstName = (name: string): string => {
  return name.trim().split(' ')[0];
};

const personaliseBrief = (brief: string, candidateName: string, filterCandidate: boolean): string => {
  if (filterCandidate) {
    const index1 = brief.indexOf('@CANDIDATE_NAME@');
    const index2 = brief.indexOf(candidateName);
    const index = Math.max(index1, index2);
    if (index !== -1) {
      return brief.substring(0, index);
    }
  }

  return brief.replaceAll('@CANDIDATE_NAME@', candidateName);
};

const depersonaliseBrief = (brief: string, candidateName: string): string => {
  brief = brief.replaceAll(candidateName, '@CANDIDATE_NAME@');
  return brief.replaceAll(getFirstName(candidateName), '@CANDIDATE_NAME@'); // This is clunky
};

export type ScenarioDetailsEditFields = {
  title: string;
  objective: string,
  brief: string
};

export const ScenarioDetails = ({ scenario, userName, onEdit, showSeedTranscript, showBackstory }: {
  scenario: GeneratedScenarioDTO | ScenarioDTO,
  userName: string,
  onEdit?: (data: ScenarioDetailsEditFields) => void,
  showSeedTranscript?: boolean,
  showBackstory?: boolean
}) => {
  const [showTranscript, setShowTranscript] = useState(true);
  const [candidate, setCandidate] = useState('');
  const [title, setTitle] = useState('');
  const [objective, setObjective] = useState('');
  const [brief, setBrief] = useState('');
  const [botFirstName, setBotFirstName] = useState('');
  const toggleTranscript = () => setShowTranscript(!showTranscript);

  const transcript = (scenario.seedResponses ?? []).map(response => ({
    role: response.isBot ? 'assistant' : 'user',
    content: response.statement,
    isSeed: true
  }) as TranscriptEntry);

  React.useEffect(() => {
    const newCandidate = userName ?? scenario.candidateName;
    setCandidate(newCandidate);
    setTitle(scenario.name);
    setObjective(scenario.objective);
    setBrief(personaliseBrief(scenario.brief, newCandidate, (onEdit === undefined)));
    setBotFirstName(scenario.botName.trim().split(' ')[0]);
  }, [scenario, userName]);

  const onTitleEdit = (e: any) => {
    setTitle(e.target.value);
    if (onEdit) {
      onEdit({
        'title': e.target.value,
        'objective': objective,
        'brief': depersonaliseBrief(brief, candidate)
      });
    }
  };

  const onObjectiveEdit = (e: any) => {
    setObjective(e.target.value);
    if (onEdit) {
      onEdit({
        'title': title,
        'objective': e.target.value,
        'brief': depersonaliseBrief(brief, candidate)
      });
    }
  };

  const onBriefEdit = (e: any) => {
    const newBrief = depersonaliseBrief(e.target.value, candidate);
    setBrief(e.target.value);
    if (onEdit) {
      onEdit({
        'title': title,
        'objective': objective,
        'brief': newBrief
      });
    }
  };

  return (
    <><Box sx={{ pl: 2, pr: 2 }}>
      {!onEdit && (
        <>
          <Typography variant="body1" fontWeight="bold" sx={{ pt: 2 }}>Title</Typography>
          <Typography variant="body2">{title}</Typography>

          <Typography variant="body1" fontWeight="bold" sx={{ pt: 2 }}>Goal</Typography>
          <Typography variant="body2">{objective}</Typography>

          {showBackstory && (
            <>
              <Typography variant="body1" fontWeight="bold" sx={{ pt: 2 }}>Backstory</Typography>
              <Typography variant="body2">{brief}</Typography>
            </>
          )}
        </>
        )
      }
      {onEdit && (
        <>
          <Typography variant="body1" fontWeight="bold" sx={{ pt: 2 }}>Title</Typography>
          <TextField fullWidth required multiline value={title} onChange={onTitleEdit}/>

          <Typography variant="body1" fontWeight="bold" sx={{ pt: 2 }}>Goal</Typography>
          <TextField fullWidth required multiline value={objective} onChange={onObjectiveEdit}/>

          {showBackstory && (
            <>
              <Typography variant="body1" fontWeight="bold" sx={{ pt: 2 }}>Backstory</Typography>
              <TextField fullWidth required multiline value={brief} onChange={onBriefEdit} />
            </>
          )}
        </>
      )}

      {showSeedTranscript && (
        <>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <Typography variant="body1" fontWeight="bold" sx={{ pt: 2 }}>Initial Conversation</Typography>
            <Button size="small" onClick={toggleTranscript} endIcon={showTranscript ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
            </Button>
          </Box>
          <Collapse in={showTranscript}>
            <Transcript 
              transcript={transcript}
              botName={botFirstName}
            />
          </Collapse>
        </>
      )}
    </Box>
    </>
  );
};

export default Navigator;
