import { useCallback, useEffect, useRef, useState } from "react";
import { io, type Socket } from "socket.io-client";

export const useSocket = (url: string, params: {
  accessToken: string | undefined,
  query?: Record<string, string | undefined>
}) => {
  const socketRef = useRef<Socket>();

  const { accessToken, query } = params;
  
  useEffect(() => {
    if (!accessToken) return;
    
    socketRef.current = io(url, {
      auth: {
        accessToken
      },
      query,
    });

    socketRef.current.on('connect', () => {
      console.log('connected to server');
    });

    socketRef.current.on('disconnect', () => {
      console.log('disconnected from server');
    });

    socketRef.current.on('error', (error) => {
      console.error('error:', error);
    });

    return () => {
      socketRef.current?.off('connect');
      socketRef.current?.off('disconnect');
      socketRef.current?.off('error');
      socketRef.current?.disconnect();
    }
  }, [accessToken]);

  const getSocket = () => socketRef.current;
  
  return {
    getSocket
  }
}
