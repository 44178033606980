import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import './App.css';
import { RootState } from './store';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Scenarios from './pages/Scenarios';
import ConversationEnd from './pages/ConversationEnd';
import Dashboard from './pages/Dashboard';
import Reports from './pages/Reports';
import Report from './pages/Report';
import Landing from './pages/Landing';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Settings from './pages/Settings';
import ConversationCheckBrowser from './components/ConversationCheckBrowser';

import { Page } from './api/types/types';
import ConversationsRoute from './pages/Conversations';

import { trackPageView, onConsent, TrackingConsent, enableTracking } from './lib/tracking';

//@ts-ignore
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';
import Cookies from 'js-cookie';
import api from './api';
import { Feature, hasFeature } from './lib/features';
import { ForgottenPassword } from './pages/ForgottenPassword';
import { VerifyPasswordReset } from './pages/VerifyPasswordReset';
import { Start } from './pages/Start';
import { SplashScreen } from './pages/scenarios/SplashScreen';
import { UserPrompt } from './pages/scenarios/UserPrompt';

const DOMAIN = 'mindbloom.ai'

const getRoute = ({ name, path }: Page) => {
  switch (name) {
    case 'conversations':
      return ConversationsRoute(path);
  }
  return null;
};

const buildTrackingConsent = (): TrackingConsent => {
  return {
    required: Cookies.get('rcl_consent_given') === 'true' || true,
    preferences: Cookies.get('rcl_preferences_consent') === 'true' || true,
    analytics: Cookies.get('rcl_statistics_consent') === 'true',
    marketing: Cookies.get('rcl_marketing_consent') === 'true',
  };
};

const isAnalyticsTrackingEnabled = (): boolean => {
  return Cookies.get('rcl_statistics_consent') === 'true';
};

const forceAnalyticsTracking = (): void => {
  Cookies.set('rcl_statistics_consent', 'true');
};

const isTrackingPermitted = (): boolean => {
  switch(window.location.hostname) {
    case `${DOMAIN}`:
    case `app.${DOMAIN}`:
    case `www.${DOMAIN}`:
      return true;
    default:
      return false;
  }
}

const TrackPageViews = () => {
  const location = useLocation();
  const isLoggedIn = Cookies.get('isAuthenticated') === 'true';
  const onCookieAccepted = () => onConsent(buildTrackingConsent());

  React.useEffect(() => {
    if (isTrackingPermitted()) {
      // If the user is logged in and tracking is not enabled, then do so because they
      // accepted the T&Cs and Privacy Policy as part of registration.
      if (isLoggedIn && !isAnalyticsTrackingEnabled()) {
        forceAnalyticsTracking();
        enableTracking();
      }
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  if (!isTrackingPermitted()) {
    return (
      <>
      </>
    );
  }
  return (
    <CookieBanner
      message="Mindbloom AI uses cookies to improve your experience. By continuing to use this site, you accept our use of cookies."
      wholeDomain={true}
      showAcceptSelectionButton={true}
      showPreferencesOption={false}
      acceptSelectionButtonText="Accept Selected"
      statisticsOptionText="Analytics"
      marketingOptionText="Marketing"
      preferencesOptionInitiallyChecked={true}
      statisticsOptionInitiallyChecked={true}
      marketingOptionInitiallyChecked={false}
      onAccept={onCookieAccepted}
      policyLink="/privacy"

      styles={{
        dialog: {
          position: 'fixed',
          bottom: '0',
          left: '0',
          right: '0',
          zIndex: '100000',
          backgroundColor: '#eee',
          padding: '10px',
          borderTop: '1px solid #aaa',
        },
        optionLabel: {
          height: 'auto',
          width: 'auto',
          minHeight: '14px',
          fontSize: '12pt',
          color: '#000',
          display: 'inline-block',
          padding: '1px 0 0 20px',
          position: 'relative',
          top: '0',
          left: '0',
          zIndex: '1',
          cursor: 'default',
          verticalAlign: 'top',
        },
        button: {}, // Allow override with App.css
        message: {
          color: '#000',
          fontSize: '16px',
          fontWeight: 'bold',
          padding: '10px',
        },
      }}
    />
  );
};

const Ping = () => {
  const location = useLocation();
  useEffect(() => {
    api.ping();
  }, [location]);
  return <></>
};

function App() {
  const pages = useSelector((state: RootState) => state.app.pages);
  const features = useSelector((state: RootState) => state.auth.features);

  return (
    <Router>
      <TrackPageViews />
      <Ping/>
      <Routes>
        {pages.map((page) => getRoute(page))}
        <Route path="/start" element={<SplashScreen />} />
        <Route path="/start/prompt" element={<UserPrompt />} />

        <Route path="/login" element={<Login />} />
        <Route path="/login/:conversationUuid" element={<Login />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/forgotten-password" element={<ForgottenPassword />} />
        <Route path="/verify-reset" element={<VerifyPasswordReset />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signup/:conversationUuid" element={<SignUp />} />
        <Route path="/" element={<Landing />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/scenarios" element={<Scenarios />} />
        <Route path="/conversation/:uuid" element={<ConversationCheckBrowser />} />
        <Route path="/conversation-end/:uuid" element={<ConversationEnd />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        
        <Route path="*" element={<Scenarios />} />


        /* FEATURE BASED ROUTES */
        {
          /* View report details */
          hasFeature(Feature.VIEW_REPORT_DETAILS, features) ? <>
            <Route path="/report/:uuid" element={<Report />} />
          </> : null
        }

        {
          /* Anonymous conversations */
          hasFeature(Feature.ANONYMOUS_CONVERSATIONS, features) ? <>
            <Route path="/conversation-end/:uuid/:correlationUuid" element={<ConversationEnd />} />
            <Route path="/conversation/:uuid/:correlationUuid" element={<ConversationCheckBrowser />} />
            <Route path="/login/:conversationUuid/:correlationUuid" element={<Login />} />
            <Route path="/signup/:conversationUuid/:correlationUuid" element={<SignUp />} />
          </> : null
        }

      </Routes>
    </Router>
  );
}

export default App;
